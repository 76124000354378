import React, { RefObject, useEffect, useState } from "react";

import { Table } from "antd";
import classNames from "classnames";

import { ArrowSVG } from "assets/icons";
import { Button } from "components/Button";
import { useWindowSize } from "hooks";

import { columns, mobileColumns, tableData } from "./tableDefinition";
import { useStoreInfoAboutExpandInLocalStorage } from "./useStoreInfoAboutExpandInLocalStorage";

import styles from "./ComparisonTiersTable.module.scss";

type Props = {
  tableRef: RefObject<HTMLDivElement>;
  defaultShowComparison?: boolean;
};

export const ComparisonTiersTable = ({ tableRef, defaultShowComparison }: Props) => {
  const { isMobile } = useWindowSize();
  const { showComparison, onToggle } = useStoreInfoAboutExpandInLocalStorage(defaultShowComparison);

  const toggleButton = (
    <div className={styles.compareTiersBtnBox}>
      <Button type={"outline"} onClick={onToggle}>
        {showComparison ? "Show less" : "Compare Tiers"}{" "}
        <ArrowSVG className={classNames({ [styles.arrowFlip]: showComparison })} />
      </Button>
    </div>
  );

  return (
    <>
      {showComparison && (
        <div className={styles.comparisonTable} ref={tableRef}>
          <div className={styles.tiersContainer}>
            <Table
              sticky
              rowClassName={(_, idx) => (idx % 2 === 0 ? styles.rowLight : styles.rowDark)}
              className={styles.table}
              dataSource={tableData}
              columns={isMobile ? mobileColumns : columns}
              pagination={false}
              footer={undefined}
              expandable={
                isMobile
                  ? {
                      defaultExpandAllRows: true,
                      showExpandColumn: false,
                      expandedRowClassName: () => styles.mobileTableRowTitle,
                      expandedRowRender: (record) => {
                        return <div>{record.features}</div>;
                      },
                    }
                  : undefined
              }
            />
          </div>
        </div>
      )}

      {toggleButton}
    </>
  );
};
