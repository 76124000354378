import { map, prop, propOr } from "ramda";

import { IAccount } from "types/account";
import { AdapterMapper } from "types/utils";

import { ProcessStatusReport } from "./proessStatusReport";

class AccountAdapterClass {
  receivingMapper = {
    email: prop("email"),
    name: prop("name"),
    tier: prop("tier"),
    type: prop("type"),
    isHighNetWorth: prop("isHighNetWorth"),
    isEnhanced: prop("isEnhanced"),
    UKFinProm: prop("UKFinProm"),
    forceEnhancedCertification: propOr(false, "forceEnhancedCertification"),
    forceSelfCertification: propOr(false, "forceSelfCertification"),
    kyc: (data: any) => ProcessStatusReport.toModel(propOr({}, "kyc", data)),
    virtualAssets: (data: any) => ProcessStatusReport.toModel(propOr({}, "virtualAssets", data)),
    selfCertification: (data: any) => ProcessStatusReport.toModel(propOr({}, "selfCertification", data)),
    fullCertification: (data: any) => ProcessStatusReport.toModel(propOr({}, "fullCertification", data)),
    privateEquity: (data: any) => ProcessStatusReport.toModel(propOr({}, "privateEquity", data)),
    assetProtection: (data: any) => ProcessStatusReport.toModel(propOr({}, "assetProtection", data)),
    promotionRequest: (data: any) => ProcessStatusReport.toModel(propOr({}, "promotionRequest", data)),
    edd: (data: any) => ProcessStatusReport.toModel(propOr({}, "edd", data)),
    tierAgreements: prop("tierAgreements"),
    verificationBlocked: prop("verificationBlocked"),
  } as const;

  toModel<T = unknown>(data: T) {
    return map<AdapterMapper<T>, IAccount>((fn) => fn(data), this.receivingMapper);
  }
}

export const AccountAdapter = new AccountAdapterClass();
